import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import * as cheerio from 'cheerio';
import slug from 'slug';


import { Spinner } from './Spinner';
import { ShouldRender } from './ShouldRender';

const FORMAT_TYPES = {
  HTML: "html",
  TEXT: "text",
};


type Result = {
  type: string;
  data: string;
};

const EMPTY_OUTPUT = 'no data loaded';
interface Props {
  /**
   * path is the folder where index.html file is located relative to the public
   * folder `public/cosmos/index.html` -> `/cosmos`
   */
  path: string;
  /** 
   * resourcePath is an absolute folder relative to the public folder.
   * i.e. `public/evmos/` -> `/evmos` 
   * if resourcePath is undefined, it is assigned same value as `path`
   */
  resourcePath?: string;
  /**
   * tocSelector is the CSS selector for which we get all the table of content notes and their
   * corresponding href values which are numeric. we then replace the IDs of the actual content
   * with the readable text values of the toc texts
   */
  tocSelector?: string;
};

const textToSlug = (text = '') => {
  if (text === '') return '';
  return slug(text);
}

export const RenderHTML: FC<Props> = React.memo(({ path, tocSelector, resourcePath }) => {
  if (!resourcePath) resourcePath = path;
  if (!tocSelector) tocSelector = '.table_of_contents-link'; // css class for each toc element
  let origin = window.location.origin;
  const url = `${origin}${path}`;

  const [result, setResult] = React.useState<Result>({} as Result);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function loadHTMLData() {
      const getHTMLResult = async (url: string): Promise<Result> => {
        const r = await fetch(url);
        if (r.status === 404) return { type: FORMAT_TYPES.TEXT, data: EMPTY_OUTPUT };
        const contentType = r.headers.get('content-type');
        if (!contentType) throw new Error('no content type available');

        // If HTML page, perform some customizations such as:
        // - re-assigning the image src to a suitable location
        // - renaming the links and hrefs to use readable texts and not numbers
        //   the texts should be slugs(lowercase letters with all spaces replaced with a '-')
        if (contentType.includes(FORMAT_TYPES.HTML)) {
          const data = await r.text();
          const $ = cheerio.load(data);

          $("img").each(function () {
            const oldSrc = $(this).attr("src");
            if (oldSrc?.startsWith('http')) return;
            const newSrc = `${origin}${resourcePath}/${oldSrc}`;
            $(this).attr("src", newSrc);
          });

          $(tocSelector).each(function () {
            const el = $(this);
            const text = el.text().trim();
            const slug = textToSlug(text);
            const id = el.attr('href')?.replace('#', '');
            const idEl = $(`#${id}`);
            el.attr('href', `#${slug}`);
            idEl.attr('id', slug);
          });
          return { type: FORMAT_TYPES.HTML, data: $.html() };
        }
        throw new Error('unsupported content type fetched');
      };

      try {
        setLoading(true);
        const result = await getHTMLResult(url);
        setResult(result);
      } catch (e: any) {
        console.log("getPage:error", e.message);
      } finally {
        setLoading(false);
      }
    }
    loadHTMLData();
  }, [url, origin, resourcePath, tocSelector]);

  React.useEffect(() => {
    const { hash } = window.location;
    if (!loading && hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }
      }, 100);
    }
  }, [loading]);

  React.useEffect(() => {
    const { hash } = window.location;
    if (!loading) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }
      }, 100);
    }
  }, [loading]);

  const props: any = {};
  if (result?.type === FORMAT_TYPES.HTML) {
    props['dangerouslySetInnerHTML'] = { __html: result.data };
  }

  if (result?.type === FORMAT_TYPES.TEXT) {
    props['children'] = (
      <div style={{ padding: '15px 0' }}>
        <Chip label={result.data} variant="outlined" />
      </div>
    );
  }

  const html = React.createElement('div', props);
  return (
    <>
      <Spinner loading={loading} />
      <ShouldRender if={!loading}>
        <Paper elevation={0}>
          <Box m={2} style={{ overflowX: "auto" }}>
            {html}
          </Box>
        </Paper>
      </ShouldRender>
    </>
  )
});
