import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { MenuListItems } from './MenuList';
import { ShouldRender } from '../ShouldRender';

import data from '../../data/app_bar.json';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    top: theme.spacing(.01),
}));

interface Props {
    isMobile: boolean;
    onClickMenu: Function;
};

export default function MenuAppBar(props: Props) {
    const { isMobile, onClickMenu } = props;
    return (
        <StyledBox>
            <AppBar
                color="default"
                elevation={1}
            >
                <Toolbar disableGutters sx={{ px: 1 }}>
                    {/* Add menu icon for mobile devices */}
                    <ShouldRender if={isMobile}>
                        <IconButton
                            edge="start"
                            aria-label="open drawer"
                            onClick={() => onClickMenu()}
                            sx={{ marginRight: 1, color: (theme) => theme.palette.grey[900] }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: 1 }} />
                        <a href="/">
                            <Box
                                component="img"
                                src={data.logo2.src}
                                alt={data.logo2.alt}
                                sx={{ height: 36, mr: 3 }}
                            />
                        </a>
                    </ShouldRender>

                    {/* Render navigation menu list if this is not a mobile device */}
                    <ShouldRender if={!isMobile}>
                        <a href="/">
                            <Box
                                component="img"
                                src={data.logo2.src}
                                alt={data.logo2.alt}
                                sx={{ height: 36, mr: 3 }}
                            />
                        </a>
                        <MenuListItems mobile={false} />
                    </ShouldRender>
                </Toolbar>
            </AppBar>
            <Offset />
        </StyledBox>
    );
}
