import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import Typography from '@mui/material/Typography';

import { ShouldRender } from '../ShouldRender';

interface Props {
    name: string;
    description: string;
    link: string;
};

export default function InfoCard(props: Props) {
    const { name, description, link } = props;
    return (
        <Card sx={{ minHeight: 150, mb: 3 }}>
            <CardContent sx={{ p: 2 }}>
                <Typography gutterBottom variant="h5" component="div">
                    {name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <ShouldRender if={link !== ""}>
                <CardActions>
                    <Link
                        underline='none'
                        color='unset'
                        href={link}
                        target="_blank"
                        sx={{ pl: 1.5, }}

                    >
                        Learn More 
                    </Link>
                        &nbsp; <LinkIcon fontSize='small' />
                </CardActions>
            </ShouldRender>
        </Card>
    );
}
