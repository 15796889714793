import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ToolsCard from './ToolsCard';
import data from '../../data/tools.json';
import { alternateGridCol } from '../grid';

export default function Tools() {
  return (
    <Box sx={{
            p: 3,
            margin: 'auto',
            bgcolor: '#fafafa',
        }}
    >
      <Grid container>
        <Grid item sm={1} />
        <Grid item sm={10} container>
            <Grid item xs sx={{ textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom>
                    {data.heading}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {data.subheading}
                </Typography>
            </Grid>
        </Grid>
        <Grid item sm={1} />
      </Grid>
      <Grid container>
        <Grid item sm={1}></Grid>
        <Grid item sm={10}>
        <Box
            sx={[
              {display: 'grid', gap: '1rem 2rem'},
              (t) => alternateGridCol(t),
            ]}>
              {data.tools.map((a, i) => (
                <ToolsCard {...a} key={i} />
              ))}
          </Box>
        </Grid>
        {/* <Grid item sm={10}>
            <Grid container spacing={2}>
                {data.tools.map((a, i) => (
                    <Grid
                        item
                        key={i}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                    >
                        <ToolsCard {...a} />
                    </Grid>
                ))}
            </Grid>
        </Grid> */}
        <Grid item sm={1}></Grid>
      </Grid>
    </Box>
  );
}
