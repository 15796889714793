import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

interface Props {
    title: string;
};

export default function ToolCard(props: Props) {
    return (
        <Card elevation={2}>
            <CardContent
                sx={{
                    minHeight: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: grey[800]
                }}
            >
                <Typography
                    gutterBottom
                    component="div"
                    variant="h6"
                    sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        marginBottom: 0,
                        lineHeight: 1.35,
                    }}
                >
                    {props.title}
                </Typography>
            </CardContent>
        </Card>
    );
}
