import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';

interface Props {
    title: string;
    image: string;
    height: string;
    lightBackground?: boolean;
    padImage?: boolean;
};

export default function ImageLink(props: Props) {
    const {
        title,
        image,
        height,
        lightBackground,
        padImage,
    } = props;
    return (
        <Card
            sx={{
                p: 0,
                minHeight: 125,
            }}
        >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height={height}
                    image={image}
                    alt={title}
                    style={{
                        backgroundColor: lightBackground ? undefined : 'black',
                        padding: padImage ? 5 : undefined,
                    }}
                />
                <CardContent
                    sx={{
                        pt: 2,
                    }}
                >
                    <Typography variant="caption" color="text.secondary">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
