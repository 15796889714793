import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import GridContainer from '../../GridContainer';
import RenderPDF from '../../RenderPDF/RenderPDF';
import StakeholderImages from './StakeholderImages';

export default function SCSecCosmosV1() {
    const pdfRef = React.createRef<HTMLDivElement>();
    return (
        <GridContainer>
            <Grid xs item>
                <Typography variant="caption">Cosmos Software Supply Chain audit/report v1</Typography>
                <StakeholderImages />
                <div>
                    <h1 id="preamble">Preamble</h1>
                </div>
                <Typography variant="body1" gutterBottom>
                    Orijtech Security has a mandate of wholly securing software infrastructure!
                    Instead of always working to defend and catch security issues, a much more pragmatic and holistic
                    approach is to build secure components from the ground up. The Cosmos ecosystem is a high value,
                    large marketcap ecosystem with 40+ decentralized and sovereign chains and they develop software in a decentralized manner.
                    Just the sheer amount of commits, technical and security decisions made by every team grow unwieldy to coordinate and secure.
                    For this reason, our approach was to partner with the world's best supply chain security teams to provide base components
                    and build up towards securing the stack.<br />Securing the stack requires providing and encouraging auditable and much more
                    secure raw materials to make this happen, for example secure images, memory safe languages, static analyzers, tooling,
                    security incident response etc.
                </Typography>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Paper
                        component="img"
                        alt="Whitehouse logo"
                        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/US-WhiteHouse-Logo.svg"
                        sx={{
                            height: 200,
                            mb: 1,
                        }}
                        elevation={1}
                    />
                </Box>

                <Typography variant="body1" gutterBottom>
                    Perhaps you might have seen the USA Presidency Biden-Harris Administration's WhiteHouse issue a novel Executive Order on Cybersecurity per
                    &nbsp; <a href="https://www.whitehouse.gov/briefing-room/presidential-actions/2021/05/12/executive-order-on-improving-the-nations-cybersecurity/">
                        https://www.whitehouse.gov/briefing-room/presidential-actions/2021/05/12/executive-order-on-improving-the-nations-cybersecurity/
                    </a>
                    such as Executive Order (E.O) 14028; incidentally many of the ideas mandated in that E.O are what we had planned for!
                    Luckily most of the software in the Cosmos ecosystem is written in the <a href="https://go.dev/">Go programming language</a>
                </Typography>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Paper
                        component="img"
                        alt="Go Gopher"
                        src="https://go.orijtech.com/images/Gopher_group.png"
                        sx={{
                            height: 200,
                            mb: 1,
                            textAlign: 'center',
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                        }}
                        elevation={1}
                    />
                </Box>
                <p>
                    which comes with batteries included Software Bill of Materials (SBOM) in the form of go.mod and go.sum files, a  <a href="https://go.googlesource.com/proposal/+/master/design/25530-sumdb.md">transparency and module authentication server, the GoSUMDB</a>; we've also got native <a href="https://go.dev/blog/vuln">Go vulnerability management</a> and above all it is a memory safe and static language! <br />
                    At Orijtech Inc, we continue to produce <a href="/tools">tools to aid in catching easy to miss yet hard to find bugs</a> and keep working on audits to eliminate threats, but also we are <a href="https://go.orijtech.com/">continual contributors to the Go programming language</a>
                </p>


                <h1 id="engagement">Engagement</h1>
                <p>
                    We surveyed the cybersecurity software supply chain ecosystem looking for the best of the best at the craft and we landed on Chainguard Inc, whose work has been influential in pushing for SBOMs (Software Bill of Materials)
                    and in thought leadership plus actionable tooling to secure software supply chains. Their prior amazing work on building the Supply Chain Levels for Software Artifacts (SLSA, pronounced Salsa) framework provides a quantifiable way of ranking/scoring the threat levels of various components in a software supply chain per
                    <a href="https://security.googleblog.com/2021/06/introducing-slsa-end-to-end-framework.html">SLSA framework</a>
                </p>


                <h1 id="report">Report</h1>
                <Typography variant="body1" component="p" gutterBottom>
                    In Q2/Q3 2022, we engaged with Chainguard Inc, working hand-in-hand with them, to apply their products, expertise work, to the Cosmos ecosystem.
                    The first artifact culminated in v1 of the Cosmos supply chain security audit which identified a bunch of glaring holes, and luckily Chainguard was thorough and also provided recommendations for how to proceed forward.
                    Their audit provided recommends such as using much smaller and more compact base images with a ton less vulnerabilities, as well as tools
                    to ensure integrity of statically linked CGO, they also provided SSDF scorecards for the various components in the ecosystem. You can read the report inlined here <a href="/chainguard_cosmos_v1.pdf">Chainguard Cosmos Audit v1.pdf</a> or rendered down below:
                </Typography>

                <Paper ref={pdfRef}>
                    <RenderPDF location='/chainguard_cosmos_v1.pdf' pdfRef={pdfRef} />
                </Paper>

                <h1 id="impact">Impact</h1>
                <br />
                <p>
                    The report, tips, images are available for the entire ecosystem!
                    The simplest recommendation so far is just to avoid using the Alpine Go Docker image and instead use distroless/static.
                    We've filed issues that got highlighed in our report such as:
                    <ul>
                        <li>
                            <Box sx={{ mb: 1 }}>
                                <Chip label="cosmos/cosmos-sdk:" />&nbsp;<a href="https://github.com/cosmos/cosmos-sdk/issues/11507"> ensure SLSA levels for this report</a>
                            </Box>
                        </li>
                        <li>
                            <Box sx={{ mb: 1 }}>
                                <Chip label="cosmos/cosmos-sdk:" />&nbsp;<a href="https://github.com/cosmos/cosmos-sdk/issues/13218"> mitigate supply chain risk of curl downloading rogue protobuf dependencies without verifying nor signing</a>
                            </Box>
                        </li>
                        <li>
                            <Box sx={{ mb: 1 }}>
                                <Chip label="cosmos/gaia" />&nbsp;<a href="https://github.com/cosmos/gaia/issues/1621"> build: use distroless/static instead of gcr.io/distroless/cc</a>
                            </Box>
                        </li>
                        <li>
                            <Box sx={{ mb: 1 }}>
                                <Chip label="cosmos/gaia:" />&nbsp;<a href="https://github.com/cosmos/gaia/issues/1620"> build: use ko to build Go applications instead of the Alpine image to statically link against CGO</a>
                            </Box>
                        </li>
                        <li>
                            <Box sx={{ mb: 1 }}>
                                <Chip label="cosmos/gaia:" />&nbsp;<a href="https://github.com/cosmos/gaia/issues/1622"> build: sign build artificats using Github Actions</a>
                            </Box>
                        </li>
                    </ul>

                    and a bunch of other PRs we filed while doing audits.
                    <br/>

                    Notably, we've been educating the various Cosmos Network teams about supply chain security, sharing and citing the report for the various teams to go forth and implement the recommendations in their own practicse.
                    <br />
                    <br />
                    In an almost related but in a different endeavour, we also produced a
                    &nbsp;<a href="/cosmos/hardening/">comprehensive security hardening guide for secure coding.</a>
                </p>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Paper
                        component="img"
                        alt="Cerberus, credit to Giphy.com"
                        src="/cerberus.gif"
                        sx={{
                            height: 200,
                            mb: 1,
                        }}
                        elevation={1}
                    />
                </Box>
            </Grid>
        </GridContainer>
    );
}
