import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

import Banner from './Banner';
import Services from './Services';

export default function Home() {
    return (
        <>
            <Banner />
            <Box sx={{
                bgcolor: grey[800],
                p: { xs: 2, sm: 3, md: 4, lg: 6, xl: 10 },
            }}
            >
                <Services />
            </Box>
        </>
    );
}
