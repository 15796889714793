import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

const Brand = () => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 'auto',
    }}
    >
        <Link
            href="https://energy.orijtech.com"
            target="_blank"
            sx={{ textDecoration: 'none', color: 'gray' }}
        >
            <Box component="img" src="/orijtech-logo+wordmark.svg" sx={{ height: 40 }} />
        </Link>
    </Box>
);

export default function Footer() {
    const helpLink = 'mailto:cyber@orijtech.com?subject=Inquiry';
    return (
        <Grid container spacing={2} sx={{ px: 6, py: 4, mt: 'auto', bgcolor: grey[900] }}>
            <Grid item xs={6}><Brand /></Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Typography component='span' variant='body2' sx={{ mr: 2 }}>
                    <Link
                        href={helpLink}
                        target="_blank"
                        sx={{ textDecoration: 'none', color: 'gray' }}
                    >
                        Get Help
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};
