import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

interface Props {
    children: any;
};

export default function GridContainer(props: Props) {
    const { children } = props;
    return (
        <Box sx={{
            p: 3,
            margin: 'auto',
            bgcolor: '#fafafa',
        }}
        >
            <Grid container>
                <Grid item sm={1} />
                <Grid item container sm={10}>
                    {children}
                </Grid>
                <Grid item sm={1} />
            </Grid>
        </Box>
    );
}
