import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RenderHTML } from 'src/components/RenderHTML';
import GridContainer from 'src/components/GridContainer';

export default function SCSecGoCodingGuide() {
    return (
        <GridContainer>
            <Grid item xs={12}>
                <Box>
                    <RenderHTML
                        path='/cosmos/go-coding-guide'
                        resourcePath='/cosmos/go-coding-guide'
                    />
                </Box>
            </Grid>
        </GridContainer>
    )
}
