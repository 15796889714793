import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

interface PagingProps {
    total: number;
    initialPage: number;
    onChangePage: Function;
};

export default function PaginatePDF(props: PagingProps) {
    const { total, onChangePage, initialPage } = props;
    const [currentPage, setCurrentPage] = React.useState(initialPage);

    React.useEffect(() => {
        setCurrentPage(initialPage);
    }, [initialPage]);

    const increment = () => {
        const nPage = currentPage + 1;
        if (nPage > total) return;
        setCurrentPage(nPage);
        onChangePage(nPage);
    };

    const decrement = () => {
        const nPage = currentPage - 1;
        if (nPage < 1) return;
        setCurrentPage(nPage);
        onChangePage(nPage);
    };

    return (
        <Box sx={{ textAlign: 'center', my: 1 }}>
            <ButtonGroup
                size="small" 
                variant="outlined"
                aria-label="outlined button group"
            >
                <Button size='small' onClick={() => decrement()} disabled={currentPage === 1} sx={{ textTransform: 'none' }}>Prev</Button>
                <Button size='small' onClick={() => increment()} disabled={currentPage === total}  sx={{ textTransform: 'none' }}>Next</Button>
            </ButtonGroup>
            &nbsp;&nbsp;
            <Chip label={`${currentPage-1} of ${total}`} />
        </Box>
    );
}
