import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RenderHTML } from "src/components/RenderHTML";
import GridContainer from "src/components/GridContainer";

export default function SCSecTendermintP2PAuditV1() {
    return (
        <GridContainer>
            <Grid item xs={12}>
                <Box>
                    <RenderHTML
                        path='/tendermint/audits/p2p/v1'
                        resourcePath='/tendermint/audits/p2p/v1'
                    />
                </Box>
            </Grid>
        </GridContainer>
    );
}
