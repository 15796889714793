import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RenderHTML } from "../../RenderHTML";
import GridContainer from "../../GridContainer";

export default function Pow2LoopOverflow() {
    return (
        <GridContainer>
            <Grid item xs={12}>
                <Box>
                    <RenderHTML
                        path='/advisory/pow2-loop-overflow'
                        resourcePath='/advisory/pow2-loop-overflow'
                    />
                </Box>
            </Grid>
        </GridContainer>
    );
}
