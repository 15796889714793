import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ListSubheader from '@mui/material/ListSubheader';

export default function TitlebarImageList() {
    return (
        <ImageList sx={{ width: '100%', /** height: 200 */ }}>
            <ImageListItem key="Subheader" cols={3}>
                <ListSubheader component="div"></ListSubheader>
            </ImageListItem>
            {itemData.map((item) => (
                <ImageListItem
                    key={item.img}
                    sx={{ width: item?.width ?? undefined }}
                >
                    <img
                        src={`${item.img}?w=150&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=150&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

const itemData = [
    {
        title: "Orijtech",
        img: "https://orijtech.com/images/logos/orijtech-logo+wordmark.png",
        author: 'Orijtech Inc.',
        width: 460,
    },
    {
        title: "Cosmos",
        img: "/cosmos/hardening/images/cosmos-wordmark-padded-dark.png",
        author: 'Cosmos',
        width: 300,
    },
    {
        title: "Chainguard",
        img: "/images/chainguard_logo.svg",
        author: 'Chainguard',
        width: 800,
    },
];
