import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import data from '../../data/audits.json';
import InfoCard from './InfoCard';
import { alternateGridCol } from '../grid';
import GridContainer from '../GridContainer';
import AuditProwess from './AuditProwess';

export default function Audits() {
  return (
    <GridContainer>
      <Box sx={{
              margin: 'auto',
              bgcolor: '#fafafa',
          }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center', mb: 5 }}>
          <Typography variant="h3">
              {data.heading}
          </Typography>
          <AuditProwess steps={data.prowess} />
        </Grid>
        <Box
          sx={[
            {display: 'grid', gap: '1rem 2rem' },
            (t) => alternateGridCol(t),
          ]}
        >
          {data.audits.map((a, i) => (
            <InfoCard {...a} key={i}/>
          ))}
        </Box>
      </Box>
    </GridContainer>
  );
}
