import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Masonry from '@mui/lab/Masonry';
import ItemCard from './ItemCard';
import data from '../../data/section2.json';

export default function Products() {
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Typography
          variant="h5"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#eee',
            fontWeight: 600,
          }}
          gutterBottom
        >
          {data.heading}
        </Typography>
      </Box>
      <Box sx={{ pl: 2, alignItems: 'center', justifyContent: 'center' }}>
        <Masonry
          columns={{ xs: 2, md: 3 }}
          spacing={{ xs: 2, sm: 3, md: 4 }}
        >
          {data.items.map((item, i) => (
            <ItemCard
              key={i}
              title={item}
            />
          ))}
        </Masonry>
      </Box>
    </>
  );
}
