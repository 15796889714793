import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RenderHTML } from "../../RenderHTML";
import GridContainer from "../../GridContainer";

export default function Render() {
    return (
        <GridContainer>
            <Grid item xs={12}>
                <Box>
                    <RenderHTML
                        path='/quicksilver/audits/v1'
                        resourcePath='/quicksilver/audits/v1'
                    />
                </Box>
            </Grid>
        </GridContainer>
    );
}
