import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ToolCard from './Card';
import data from '../../data/cve.json';
import { alternateGridCol } from '../grid';

export default function CVE() {
  return (
    <Box sx={{
            p: 1,
            margin: 'auto',
            bgcolor: '#fafafa',
        }}
    >
      <Grid container>
        <Grid item sm={1} />
        <Grid item sm={10} container>
            <Grid item xs sx={{ textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom>
                    {data.heading}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {data.subheading}
                </Typography>
            </Grid>
        </Grid>
        <Grid item sm={1} />
      </Grid>
      <Grid container>
        <Grid item sm={1}></Grid>
        <Grid item sm={10}>
          <Box
            sx={[
              {display: 'grid', gap: '1rem 2rem'},
              (t) => alternateGridCol(t),
            ]}
          >
            {data.cves.map((a, i) => (
              <ToolCard {...a} key={i} />
            ))}
          </Box>
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>
    </Box>
  );
}
