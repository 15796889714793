import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import GridContainer from "../../GridContainer";
import RenderPDF from '../../RenderPDF/RenderPDF';

export default function Render() {
    const pdfRef = React.createRef<HTMLDivElement>();
    return (
        <GridContainer>
            <Grid item xs={12}>
                <Paper ref={pdfRef}>
                    <RenderPDF
                        location='/chainguard_cosmos_v1.pdf'
                        pdfRef={pdfRef}
                        size='small'
                    />
                </Paper>
            </Grid>
        </GridContainer>
    );
}
