import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ShouldRender } from '../ShouldRender';
import { CardActionArea } from '@mui/material';

interface Props {
    name: string;
    logo: string;
    logoType: string; // valid values are: "image" or "text" or "icon"
    description: string;
    link: string;
};

export default function InfoCard(props: Props) {
    const { name, logo, logoType, description, link } = props;
    return (
        <CardActionArea LinkComponent="a" href={link || "#"}>
            <Card
                sx={{
                    height: "100%",
                    borderTop: '.5rem solid rgb(138,35,135)',
                    position: 'relative'
                }}
            >
                <ShouldRender if={logoType === "image"}>
                    <Box
                        component="img"
                        src={logo}
                        sx={{
                            width: "100%",
                            textAlign: "center",
                            p: 1
                        }}
                    />
                </ShouldRender>
                <ShouldRender if={logoType === "text"}>
                    <CardHeader
                        title={
                            <Typography component="h3" gutterBottom>
                                {logo}
                            </Typography>
                        }
                    />
                </ShouldRender>
                <CardContent sx={{ p: 2 }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        </CardActionArea>
    );
}
