import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FenceIcon from '@mui/icons-material/Fence';
import { Link, LinkProps, useResolvedPath, useMatch } from 'react-router-dom';


function CustomLinkItem({ children, to, ...props }: LinkProps) {
    let resolved = useResolvedPath(to);
    const path = resolved.pathname;
    let match = useMatch({ path, end: path === '/' });
    return (
        <ListItem dense sx={{ p: 0 }}>
            <ListItemButton
                selected={match ? true : false}
                component={Link}
                to={to}
                {...props}
            >
                {children}
            </ListItemButton>
        </ListItem>
    );
}

interface MenuListItemsProps {
    mobile?: boolean;
};

export function MenuListItems(props: MenuListItemsProps) {
    const { mobile } = props;
    return (
        <List sx={{
            // selected and (selected + hover) states
            '&& .Mui-selected, && .Mui-selected:hover': {
                bgcolor: '#dedede',
                '&, & .MuiListItemIcon-root': {
                    color: '#333',
                },
            },
            display: 'flex',
            flexDirection: mobile ? 'column' : 'row',
            padding: 0,
        }}>
            <CustomLinkItem to="/audits">
                <ListItemIcon sx={{ minWidth: 25 }}>
                    <VerifiedUserIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary="Audits" />
            </CustomLinkItem>
            <CustomLinkItem to="/tools">
                <ListItemIcon sx={{ minWidth: 25 }}>
                    <HomeRepairServiceIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary="Tools" />
            </CustomLinkItem>
            <CustomLinkItem to="/cves">
                <ListItemIcon sx={{ minWidth: 25 }}>
                    <FactCheckIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary="CVEs" />
            </CustomLinkItem>
            <CustomLinkItem to="/scsec/">
                <ListItemIcon sx={{ minWidth: 10 }}>
                    <FenceIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary="SupplyChain" />
            </CustomLinkItem>
        </List>
    );
}


const width: number = 230;

interface MenuListProps {
    open: boolean;
    onClick: Function;
    mobile: boolean;
};

export default function MenuList(props: MenuListProps) {
    const { open, onClick, mobile } = props;
    return (
        <div>
            <Drawer
                open={open}
                onClose={() => onClick()}
            >
                <Box
                    sx={{ width, mt: 0 }}
                    onClick={() => onClick()}
                    role="presentation"
                >
                    <MenuListItems
                        mobile={mobile}
                    />
                </Box>
            </Drawer>
        </div>
    );
}
