import { FC } from 'react';

interface Should {
  if: boolean;
  children: any;
};

export const ShouldRender: FC<Should> = (props) => {
  return props.if ? props.children : null
};
