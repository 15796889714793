import 'react-pdf/dist/esm/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Document, Page } from 'react-pdf';

import { Spinner } from '../Spinner';
import { ShouldRender } from '../ShouldRender';
import PaginatePDF from './PaginatePDF';
import { useDivSize } from '../hooks/useDivSize';

interface Props {
    location: string;
    pdfRef: React.RefObject<HTMLDivElement>;
    size?: 'default'|'small'|'large';
};

export default function RenderPDF(props: Props) {
    const { location, pdfRef, size = 'default' } = props;
    const { width } = useDivSize(pdfRef);
    const [error, setError] = React.useState('');
    const [loaded, setLoaded] = React.useState(false);
    const [numPages, setNumPages] = React.useState(1);
    const [pageNumber, setPageNumber] = React.useState(1);

    let scale = size === 'default' ? 1 : (size === 'large' ? 1.2 : 0.5);

    const onLoadSuccess = () => {
        setLoaded(true);
    };

    const onLoadError = (e: Error) => {
        setError('Error loading page');
    };

    const onDocumentLoadSuccess = (data: { numPages: number }) => {
        setNumPages(data.numPages);
    };

    const onChangePage = (n: number) => {
        setPageNumber(n);
    };

    useEffect(() => {
        console.log({ loaded });
    }, [loaded]);

    return (
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
                <ShouldRender if={!error}>
                    <PaginatePDF
                        total={numPages}
                        onChangePage={onChangePage}
                        initialPage={pageNumber}
                    />
                    <Document
                        loading={<Spinner loading={true} />}
                        file={location}
                        onLoadError={(e: any) => onLoadError(e)}
                        onSourceSuccess={() => onLoadSuccess()}
                        onLoadSuccess={onDocumentLoadSuccess}
                        error={<p>File not found.</p>}
                    >
                        <Page
                            pageNumber={pageNumber}
                            scale={scale}
                            width={width}
                        />
                    </Document>
                    <PaginatePDF
                        total={numPages}
                        onChangePage={onChangePage}
                        initialPage={pageNumber}
                    />
                </ShouldRender>
            </Box>
        </Grid>
    );
}
