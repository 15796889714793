import { Theme } from "@mui/material";

export function alternateGridCol(theme: Theme) {
    const mapping = [
        {point: 'xs', col: 2},
        {point: 'md', col: 3},
        {point: 'lg', col: 4},
    ];
    return mapping.reduce((prev, next) => {
        return {
            ...prev,
            [theme.breakpoints.up(next.point as any)]: {
            gridTemplateColumns: `repeat(${next.col}, 1fr)`
            }
        }
    }, {} as any)
}
