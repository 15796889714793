import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';

import { ShouldRender } from '../ShouldRender';
import { grey } from '@mui/material/colors';

interface Props {
    name: string;
    description: string;
    link: string;
    signup: string;
};

export default function ToolsCard(props: Props) {
    const { name, description, link, signup } = props;
    return (
        <Card sx={{ minHeight: 300, mb: 3, position: 'relative' }}>
            <CardHeader
                avatar={<CodeIcon fontSize="large" />}
                title={
                    <Typography gutterBottom variant="caption">
                        {name}
                    </Typography>
                }
                sx={{ mb: 0, pb: 0 }}
            />
            <CardContent sx={{ p: 2 }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {description}
                </Typography>
            </CardContent>
            <ShouldRender if={link !== "" || signup !== ""}>
                <CardActions
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        p: 2,
                        width: '100%',
                        bgcolor: grey['100'],
                    }}
                >
                    <ShouldRender if={link !== ""}>
                        <Link
                            underline='none'
                            color='unset'
                            href={link}
                            target="_blank"
                            sx={{ pl: 1 }}

                        >
                            <Button size="small" variant="contained" color="inherit">Learn More</Button> 
                        </Link>
                    </ShouldRender>
                    <ShouldRender if={signup !== ""}>
                        <Link
                            underline='none'
                            color='unset'
                            href={signup}
                            target="_blank"
                        >
                            <Button size="small" variant="contained">Sign up</Button> 
                        </Link>
                    </ShouldRender>
                </CardActions>
            </ShouldRender>
        </Card>
    );
}
